import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/* Captcha api */
/**
 * 取得Captcha的Code
 */
export const GetCaptchaCode = () => baseAxios.post('/FrontEnd/Captcha/GetCaptchaCode');

/**
 * 取得Captcha的圖片
 * @param {object} data { code }
 */
export const GetCaptchaBase64 = (code) => baseAxios.post('/FrontEnd/Captcha/GetCaptchaBase64', { code });
