<template>
  <!-- 主要內容 -->
  <section class="container pb-8">
    <div class="row">
      <div class="col-12 col-md-5 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">忘記密碼</h2>
        <!-- 步驟一 -->
        <el-form
          style="width: 100%;"
          :model="MemberForgetPasswordForm"
          label-position="top"
          :rules="MemberForgetPasswordRules"
          ref="MemberForgetPasswordForm"
          class="cs-form"
          v-show="IsNowType === 1"
          @submit.prevent.native
        >
          <div class="form-group mb-3">
            <h5>步驟一、請輸入申請時所填寫的帳號，我們將協助您【重新設定新密碼】。</h5>
          </div>
          <div class="form-group mb-3">
            <el-form-item prop="memberAccount">
              <label class="text-muted" for="memberAccount"
                >帳號<span class="pl-1">Account</span></label
              >
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="memberAccount"
                placeholder="帳號"
                v-model="MemberForgetPasswordForm.memberAccount"
              />
            </el-form-item>
          </div>
          <div class="form-group row">
            <label class="sr-only" for="memberVerify">驗證碼</label>
            <div class="col-8 col-md-6 col-lg-8">
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="memberVerify"
                placeholder="驗證碼"
                v-model="MemberForgetPasswordForm.captchaCode"
              />
            </div>
            <div class="col-4 d-flex align-items-center">
              <img
                class="img-verify"
                :src="`data:image/png;base64,${CodeImg}`"
                alt="驗證碼"
                @click="GetCaptchaCode"
              />
            </div>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="MemberForgetPassword"
              >
                <span class="small">確定送出</span>
              </button>
            </div>
          </div>

          <div class="text-center">
            <router-link class="link-hover-accent pr-4" to="/login">
              返回會員登入
            </router-link>
          </div>
        </el-form>
        <!-- 步驟二 -->
        <el-form
          style="width: 100%;"
          :model="MemberCheckCaptchaCodeForm"
          label-position="top"
          :rules="MemberCheckCaptchaCodeRules"
          ref="MemberCheckCaptchaCodeForm"
          class="cs-form"
          v-show="IsNowType === 2"
          @submit.prevent.native
        >
          <div class="form-group mb-3">
            <h5>
              步驟二、<span style="color: red;">請點擊【發送驗證碼】按鈕</span>，
              我們會將驗證碼以電子郵件寄給您申請時所填寫的信箱， 寄送信箱為【{{
                MemberCheckCaptchaCodeForm.memberMail
              }}】。
            </h5>
          </div>
          <div class="form-group row">
            <label class="sr-only" for="captchaCode">信箱驗證碼</label>
            <div class="col-6 col-md-6 col-lg-8">
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="captchaCode"
                placeholder="信箱驗證碼"
                v-model="MemberCheckCaptchaCodeForm.captchaCode"
              />
            </div>
            <div class="col-6 col-lg-4 d-flex align-items-center">
              <button
                type="button"
                :disabled="isDisabled"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="MemberSendCaptchaCode"
              >
                <span class="small">{{ CaptchaCodeTxt }}</span>
              </button>
            </div>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="MemberCheckCaptchaCode"
              >
                <span class="small">確定送出</span>
              </button>
            </div>
          </div>
        </el-form>
        <!-- 步驟三 -->
        <el-form
          style="width: 100%;"
          :model="SaveMemberForgetPasswordForm"
          label-position="top"
          :rules="SaveMemberForgetPasswordRules"
          ref="SaveMemberForgetPasswordForm"
          class="cs-form"
          v-show="IsNowType === 3"
          @submit.prevent.native
        >
          <div class="form-group mb-3">
            <h5>步驟三、請在五分鐘內【重新設定新密碼】。</h5>
          </div>
          <div class="form-group mb-3">
            <el-form-item prop="memberNewPassword">
              <label class="text-muted" for="memberNewPassword"
                >新密碼<span class="pl-1">New Password</span></label
              >
              <input
                type="password"
                class="form-control form-control-lg border-accent"
                id="memberNewPassword"
                placeholder="新密碼"
                v-model="SaveMemberForgetPasswordForm.memberNewPassword"
              />
            </el-form-item>
          </div>
          <div class="form-group mb-3">
            <el-form-item prop="memberConfirmPassword">
              <label class="text-muted" for="memberConfirmPassword"
                >確認密碼<span class="pl-1">Confirm Password</span></label
              >
              <input
                type="password"
                class="form-control form-control-lg border-accent"
                id="memberConfirmPassword"
                placeholder="確認密碼"
                v-model="SaveMemberForgetPasswordForm.memberConfirmPassword"
              />
            </el-form-item>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="SaveMemberForgetPassword"
              >
                <span class="small">確定送出</span>
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import {
  MemberForgetPassword,
  MemberSendCaptchaCode,
  MemberCheckCaptchaCode,
  SaveMemberForgetPassword,
} from '@/api/MemberApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      IsNowType: 1,
      timeOutProcessId: null,
      time: 60,
      isDisabled: false,
      CaptchaCodeTxt: '發送驗證碼',
      CodeImg: '',
      MemberForgetPasswordForm: {
        // model data
        memberAccount: '',
        code: '',
        captchaCode: '',
      },
      MemberForgetPasswordRules: {
        // 檢查輸入的值規則
        memberAccount: [{ required: true, message: '請輸入帳號', trigger: 'blur' }],
      },
      MemberCheckCaptchaCodeForm: {
        memberAccount: '',
        captchaCode: '',
        guid: '',
        memberMail: '',
      },
      MemberCheckCaptchaCodeRules: {
        // 檢查輸入的值規則
        captchaCode: [{ required: true, message: '請輸入信箱驗證碼', trigger: 'blur' }],
      },
      SaveMemberForgetPasswordForm: {
        memberAccount: '',
        newGuid: '',
        memberNewPassword: '',
        memberConfirmPassword: '',
      },
      SaveMemberForgetPasswordRules: {
        // 檢查輸入的值規則
        memberNewPassword: [
          { required: true, message: '請輸入新密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
        memberConfirmPassword: [
          { required: true, message: '請輸入確認密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    // 一開始執行的地方
    this.GetCaptchaCode();
  },
  methods: {
    // 你的 js function
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.MemberForgetPasswordForm.code = response.data.data.code;
          GetCaptchaBase64(this.MemberForgetPasswordForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    MemberForgetPassword() {
      // 忘記密碼
      this.MemberCheckCaptchaCodeForm.memberAccount = this.MemberForgetPasswordForm.memberAccount;
      this.SaveMemberForgetPasswordForm.memberAccount = this.MemberForgetPasswordForm.memberAccount;
      MemberForgetPassword(this.MemberForgetPasswordForm).then((response) => {
        if (response.data.statusCode === 0) {
          this.IsNowType = 2; // 進入第二步驟
          this.MemberCheckCaptchaCodeForm.guid = response.data.data.guid;
          this.MemberCheckCaptchaCodeForm.memberMail = response.data.data.memberMail;
          // 不馬上執行
          // this.MemberSendCaptchaCode();
        } else {
          this.GetCaptchaCode();
          this.IsNowType = 1; // 第一個步驟
          this.MemberCheckCaptchaCodeForm.memberAccount = ''; // 清空資料
          this.MemberCheckCaptchaCodeForm.guid = ''; // 清空資料
          this.MemberCheckCaptchaCodeForm.memberMail = ''; // 清空資料
          this.SaveMemberForgetPasswordForm.memberAccount = ''; // 清空資料
        }
      });
    },
    MemberSendCaptchaCode() {
      // 發送驗證信(一分鐘一次)
      MemberSendCaptchaCode(this.MemberCheckCaptchaCodeForm).then((response) => {
        if (response.data.statusCode === 0) {
          this.setTimeoutCountdown_CaptchaCode(); // 倒數60秒
          this.isDisabled = true;
          this.$message({
            showClose: true,
            type: 'success',
            message: '發送成功',
          });
        }
      });
    },
    MemberCheckCaptchaCode() {
      // 驗證信箱的驗證碼
      if (this.MemberCheckCaptchaCodeForm.captchaCode !== '') {
        MemberCheckCaptchaCode(this.MemberCheckCaptchaCodeForm).then((response) => {
          if (response.data.statusCode === 0) {
            this.IsNowType = 3; // 進入第三步驟
            this.SaveMemberForgetPasswordForm.newGuid = response.data.data.newGuid;
          }
        });
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '請輸入信箱驗證碼',
        });
      }
    },
    SaveMemberForgetPassword() {
      // 修改密碼
      this.$refs.SaveMemberForgetPasswordForm.validate((valid) => {
        if (valid) {
          SaveMemberForgetPassword(this.SaveMemberForgetPasswordForm).then((response) => {
            if (response.data.statusCode === 0) {
              this.IsNowType = 1; // 回第一步驟
              this.$message({
                showClose: true,
                type: 'success',
                message: '修改成功。請使用新密碼進行登入。',
              });
              // 清除
              this.MemberForgetPasswordForm = {
                memberAccount: '',
                code: '',
                captchaCode: '',
              };
              this.MemberCheckCaptchaCodeForm = {
                memberAccount: '',
                captchaCode: '',
                guid: '',
                memberMail: '',
              };
              this.SaveMemberForgetPasswordForm = {
                memberAccount: '',
                newGuid: '',
                memberNewPassword: '',
                memberConfirmPassword: '',
              };
              this.$router.push('/Login');
            }
          });
        }
      });
    },
    cleanTimeOut() {
      clearTimeout(this.timeOutProcessId);
      window.clearInterval(this.timeOutRefresh);
    },
    setTimeoutCountdown_CaptchaCode() {
      this.timeOutProcessId = setTimeout(() => {
        // time
        this.time -= 1;
        if (this.time === 0) {
          this.cleanTimeOut();
          this.time = 60;
          this.isDisabled = false;
          this.CaptchaCodeTxt = '發送驗證碼';
        } else {
          this.CaptchaCodeTxt = `倒數${this.time}秒`;
          this.setTimeoutCountdown_CaptchaCode();
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
